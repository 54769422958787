import dayjs from 'dayjs'
import scheduleData from '../../static/data/schedule.json'
import range from 'lodash/range'

export const TIMEF = 'h:mm a'

export const teacherForClass = (day, time) => {
  if (scheduleData[day] && scheduleData[day][time]) {
    return scheduleData[day][time].teacher
  }
  return
}

export const displayTime = (time, format = TIMEF) => {
  const [h, m] = time.split(':')
  return dayjs()
    .hour(h)
    .minute(m)
    .format(format)
}

export const demosOnDay = day =>
  Object.entries(scheduleData[day])
    .filter(([time, data]) => data.demo)
    .map(([time, data]) => time)

const hasDemo = dayData => Object.values(dayData).filter(c => c.demo).length > 0

export const daysWithDemos = Object.entries(scheduleData)
  .map(([day, data]) => (hasDemo(data) ? day : null))
  .filter(Boolean)

export const getDemoDates = day => {
  const FORMAT = 'MM/DD/YYYY'
  const daysOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]
  const dayIndex = daysOfWeek.indexOf(day)
  const now = dayjs()
  const thisWeek = dayjs().day(dayIndex)
  const first = thisWeek.isAfter(now, 'day')
    ? thisWeek
    : thisWeek.add(1, 'week')
  return range(0, 10).map(n => first.add(n, 'week').format(FORMAT))
}

export const getClassData = (day, time) => scheduleData[day][time]
