import React from 'react'
import capitalize from 'lodash/capitalize'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Box, Text, Heading } from '../components/ui'
import { displayTime } from '../utils/schedule'

const ContactDetails = ({ state }) => {
  if (!state || !state.email) {
    return null
  }

  const {
    firstName,
    lastName,
    email,
    demoClass,
    demoDay,
    demoTime,
    demoDate,
    message,
  } = state

  return (
    <Box variant="layout.section">
      <Heading>Thank you, your request is confirmed!</Heading>
      {demoClass ? (
        <Text>
          Demo Scheduled on {capitalize(demoDay)} {demoDate} at{' '}
          {displayTime(demoTime)}
        </Text>
      ) : null}
      <Text>
        Name: {firstName} {lastName} {`<${email}>`}
      </Text>
      <Text>Message: {message}</Text>
    </Box>
  )
}

const FirstClassInfo = ({ location }) => {
  const { state = {} } = location
  console.log('location', state)
  return (
    <Layout>
      <SEO title="My First Class" />
      <ContactDetails state={state} />
      <Box variant="layout.section">
        <Heading>What do I need to know for my first class?</Heading>
        <Text>
          Please arrive 5 minutes early to fill out the paperwork and meet
          everyone.
        </Text>
        <Text>
          Wear loose comfortable pants, a t-shirt, and socks. If you get cold,
          bring a sweatshirt. Do not wear shorts, tank tops, tights, jewelry, or
          fragrance.
        </Text>
        <Text>
          Parking is available in the rear and on the side of the building. We
          are located through the first red door on the right in the back.
          Please Note: we are on the second floor up a flight of stairs.
        </Text>
        <Text>We look forward to meeting you!</Text>
      </Box>
    </Layout>
  )
}

export default FirstClassInfo
